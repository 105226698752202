import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import AboutPageContent from "../components/AboutContent"

// About Page GraphQL Queries
export const query = graphql`
  query about {
    prismicAboutPage {
      data {
        about_page_copy {
          text
          type
        }
        about_page_title {
          text
          type
        }
        resume_title {
          text
          type
        }
        body {
          slice_type
          primary {
            resume_company_name {
              text
              type
            }
            resume_job_title {
              type
              text
            }
            resume_job_description {
              text
              type
            }
            resume_job_time_span {
              text
              type
            }
          }
        }
      }
      url
      uid
    }
    site {
      siteMetadata {
        description
        title
        author
        backgrounds {
          black
          normal
          transparent
        }
      }
    }
  }
`

const About = ({ data }) => {
  const about = data.prismicAboutPage
  if (!about) return null

  return (
    <>
      <Helmet title={`${data.site.siteMetadata.title} - ${about.uid}`} />
      <AboutPageContent post={about} />
    </>
  )
}

export default About
