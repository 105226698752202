import React, { useEffect } from "react"

const AboutPageContent = ({ post }) => {
  const about = post.data

  useEffect(() => {
    document.body.style.background = "#000"
  })

  return (
    <div className="about">
      <h1>{about.about_page_title[0].text}</h1>
      <div className="about-content">
        {about.about_page_copy.map((copy, i) => {
          return <p key={i}>{copy.text}</p>
        })}
      </div>
      <h1>{about.resume_title[0].text}</h1>
      <div className="resume">
        {about.body.map((slice, i) => (
          <div className="job-posting" key={i}>
            <div className="job-desc">
              <h3 className="company">
                {slice.primary.resume_company_name[0].text}
              </h3>
              <p className="title">{slice.primary.resume_job_title[0].text}</p>
              <p className="time-span">
                {slice.primary.resume_job_time_span[0].text}
              </p>
            </div>
            <div className="job-history-summary">
              <ul>
                {slice.primary.resume_job_description.map((job, i) => {
                  return <li key={i}> {job.text} </li>
                })}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AboutPageContent
